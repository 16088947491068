import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import ForDrivers from "../components/OurCompany/ForDrivers/ForDrivers";

import Favicon from "../images/Favicon.png";

import ogImage from "../images/Metadata/for-drivers.jpg"

const ForDriversPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>For Drivers - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                      content="Drivosity equips restaurant delivery drivers with safety, efficiency, and financial benefits. Earn more with better tips, faster deliveries, and improved fuel economy. Discover how our GPS-enabled car toppers and DrivosityGO app support and protect drivers. Book a demo to learn more."/>

                <meta property="og:title" content="For Drivers - Drivosity"/>
                <meta property="og:description" content="Drivosity equips restaurant delivery drivers with safety, efficiency, and financial benefits. Earn more with better tips, faster deliveries, and improved fuel economy. Discover how our GPS-enabled car toppers and DrivosityGO app support and protect drivers. Book a demo to learn more."/>
                <meta property="og:image" content={`${process.env.GATSBY_SITE_URL}${ogImage}`}/>
                <meta property="og:image:alt" content="Smiling woman sitting in the driver’s seat of a car, representing professional drivers and vehicle-related services."/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={`${process.env.GATSBY_SITE_URL}/for-drivers`}/>
            </Helmet>
            <Layout component={<ForDrivers/>}/>
        </>
    )
}

export default ForDriversPage
